import templateFunctions from 'oneapp/src/utils/template/functions';

/**
 * A pattern that matches {{<variable>}}, {{<function>(<argument>)}} and $<name>="{{<function>(<argument>)}} or {{<variable>}}"
 */

class TemplateEngine {
	constructor() {
		const baseRegexp = '{{((\\w+)\\(([\\w.]+)\\)|[\\w.]+)}}';

		// match $<name>="{{<function>(<argument>)}} or {{<variable>}}"
		this.regexpDataReplace = new RegExp(`(?:\\$[\\w]{1,}="${baseRegexp}")`, 'g');

		// match {{<variable>}}, {{<function>(<argument>)}}
		this.regexpBase = new RegExp(baseRegexp, 'g');
	}

	/**
	 * @param {String} template
	 * @param {Object} data
	 * @returns {String}
	 */
	render(template, data) {
		if (!template) {
			return template;
		}

		return template
			.replace(this.regexpDataReplace, replacer.bind(data))
			.replace(this.regexpBase, replacer.bind(data));
	}
}

/**
 * Process replace for found values
 * @param {String} placeholder
 * @param {String} variable
 * @param {String} functionName
 * @param {String} argument
 * @returns {String}
 */
function replacer(placeholder, variable, functionName, argument) {
	if (functionName) {
		if (functionName in templateFunctions) {
			return templateFunctions[functionName](app.util.getDeepProperty(argument, this));
		} else {
			return placeholder;
		}
	}

	const propertyValue = app.util.getDeepProperty(variable, this);

	if (!propertyValue) {
		return '';
	}

	if (!this.skipXSSValidation && typeof propertyValue === 'string') {
		return propertyValue.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '');
	}

	return propertyValue;
}

export default new TemplateEngine();
